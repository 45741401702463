import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Account } from "@radixdlt/radix-dapp-toolkit"
import { useState, Fragment, useEffect } from "react";
import { classNames, shortenAddress } from "~/lib/util"
import { useRdtState } from "~/lib/rdt/hooks/useRdtState";

const STORAGE_KEY = 'radixplaza:selected_account';

type Props = {
	mode?: null | 'light' | 'dark',
	onAccount: (value: Account) => void
}

export default function AccountSelector({ mode, onAccount }: Props) {
	const walletData = useRdtState();

	const [account, setAccount] = useState<Account | null>(null);
	const [accounts, setAccounts] = useState<Account[]>([]);
	
   useEffect(() => {
      if (walletData?.accounts.length) {
         setAccounts(walletData?.accounts);

         const selectedAccount = window.localStorage.getItem(STORAGE_KEY);

         if (selectedAccount) {
            const account = walletData?.accounts.filter((a) => a.address === selectedAccount);

            if (account && account?.length > 0) {
               changeAccount(account[0]);
               return;
            }
         }

         changeAccount(walletData?.accounts[0]);
      }
   }, [walletData]);

   function changeAccount(account: Account) {
      setAccount(account);
      window.localStorage.setItem(STORAGE_KEY, account.address);

      if (onAccount) onAccount(account);
   }

	return account && accounts.length > 1 ? (
      <section
         className={classNames(
            mode == 'dark' ? 'bg-primary-800 text-white' : 'bg-white text-black',
            'flex flex-col rounded-lg shadow-xl px-6 py-4 hover:scale-105 transition duration-500 cursor-pointer object-cover bg-white/70 relative'
         )}
      >
         <label htmlFor="account" className={classNames(mode == 'dark' ? 'text-orange-300' : ' text-orange-800', 'text-sm')}>
            Account
         </label>
         <Listbox name="account" value={account} onChange={changeAccount}>
            {({ open }) => (
               <>
                  <div className="relative">
                     <Listbox.Button
                        className={classNames(
                           mode == 'dark' ? 'hover:text-primary-200' : 'hover:text-primary-500',
                           'relative w-full rounded-md pr-10 py-1 text-left focus:outline-3 focus:ring-1 focus:ring-primary-200 focus:border-primary-300'
                        )}
                     >
                        <div className="flex justify-between items-center">
                           <p className="title font-semibold">{account.label || 'Unknown'}</p>
                           <p className="name text-xs">{shortenAddress(account.address)}</p>
                        </div>
                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                           <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                     </Listbox.Button>

                     <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                           {accounts.map((account) => (
                              <Listbox.Option
                                 key={account.address}
                                 value={account}
                                 className="text-black hover:text-primary-500 hover:bg-neutral-100 relative cursor-default select-none py-2 pl-3 pr-9"
                              >
                                 {({ selected }) => (
                                    <>
                                       <div className="flex items-center">
                                          <div className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}>
                                             <p className="title font-semibold">{account.label || 'Unknown'}</p>
                                             <p className="name text-xs text-gray-700">{account.address}</p>
                                          </div>
                                       </div>
                                    </>
                                 )}
                              </Listbox.Option>
                           ))}
                        </Listbox.Options>
                     </Transition>
                  </div>
               </>
            )}
         </Listbox>
      </section>
   ) : null;
}